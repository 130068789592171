import React from 'react';
import hasher from 'hasher';
import update from 'immutability-helper';
import Utils from '../../js/utils.js';
import GraphContainer from '../graph/graphContainer.jsx';
import TopPanel from '../topPanel/topPanel.jsx';
import SidePanel from '../sidePanel/sidePanel.jsx';
import SearchBar from '../topPanel/Search/search.jsx';
import ControlPanel from '../graph/header/controlPanel.jsx';

class MainPanelDetail extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.setActiveProbe = this.setActiveProbe.bind(this);
        this.setGraphType = this.setGraphType.bind(this);
        this.setAccuracyType = this.setAccuracyType.bind(this);
        this.setEpochWithEndpoint = this.setEpochWithEndpoint.bind(this);
        this.setEpochFromZoom = this.setEpochFromZoom.bind(this);
        this.generateURL = this.generateURL.bind(this);
        this.getCatList = this.getCatList.bind(this);
        this.triggerSidebarAction = this.triggerSidebarAction.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
    }

    getInitialState() {
        const data = this.props.graph;
        this.isNarrow = window.innerWidth <= 1000;
        let epoch = Utils.isSet(data) && Utils.isSet(data.epoch) ? data.epoch : 86400;
        epoch = epoch < 60 ? 60 : epoch;
        return {
            ipDetails: {
                epoch,
                probe: Utils.isSet(data) && Utils.isSet(data.probe) ? data.probe : `slave=${this.props.probesList[0]}`,
                endPoint: Utils.isSet(data) && Utils.isSet(data.endPoint) ? data.endPoint : Utils.getTimeNow(),
                graphType: Utils.isSet(data) && Utils.isSet(data.graphType) ? data.graphType : 'basic',
                accuracy: 'normal',
                customEpoch: false,
                isFavourite: false,
            },
            sidePanelStatus: !this.isNarrow,
        };
    }

    // setters --------------------------------------------------
    setActiveProbe(data) {
        const newState = update(this.state, { ipDetails: { probe: { $set: data } } });
        this.setState(newState);
    }

    setGraphType(type) {
        const newState = update(this.state, { ipDetails: { graphType: { $set: type } } });
        this.setState(newState);
    }

    setAccuracyType(type) {
        const newState = update(this.state, { ipDetails: { accuracy: { $set: type } } });
        this.setState(newState);
    }

    setEpochWithEndpoint(epoch, endpoint, epochType) {
        epoch = epoch < 60 ? 60 : epoch;
        const newState = update(this.state, {
            ipDetails: {
                customEpoch: { $set: epochType },
                epoch: { $set: epoch },
                endPoint: { $set: endpoint },
            },
        });
        this.setState(newState);
    }

    setEpochFromZoom(epoch, to, res) {
        epoch = epoch < 60 ? 60 : epoch;
        const newState = update(this.state, {
            ipDetails: {
                epoch: { $set: epoch },
                endPoint: { $set: to },
                customEpoch: { $set: res },
            },
        });
        this.setState(newState);
    }
    // ----------------------------------------------------------

    generateURL() {
        const graph = this.state.ipDetails;
        const ip = this.props.selectedIP;
        let url = `detail?ip=${ip}&`;
        if (!graph.customEpoch) {
            url += `epoch=${graph.epoch}&`;
            url += `probe=${graph.probe.split('=')[1]}&`;
            url += `graphType=${graph.graphType}`;
        } else {
            url += `end=${graph.endPoint}&`;
            url += `epoch=${graph.epoch}&`;
            url += `probe=${graph.probe.split('=')[1]}&`;
            url += `graphType=${graph.graphType}`;
        }
        Utils.setHashSilently(url);
    }

    getCatList() {
        const ip = this.props.selectedIP;
        const { config } = this.props;
        if (!Utils.isSet(this.props.config) && !Utils.isSet(config.ping.ips[ip])) {
            return [];
        }
        const categories = Utils.getPropertyOfObject(['ping', 'ips', ip, 'cat'], config, []);
        const str = Utils.getPropertyOfObject([0], categories, ''); // only the firt argument determines category
        const cat = str.substring(0, str.length - 2); // removes '->' from the end
        return cat.split('->');
    }

    getAS = () => {
        const ip = this.props.selectedIP;
        const { as } = this.props.config.ping.ips[ip];
        return as;
    }

    triggerSidebarAction() {
        this.setState(prevState => ({ sidePanelStatus: !prevState.sidePanelStatus }));
    }

    getMobileSearchBox(props) {
        return (
            <SearchBar {...props} />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.selectedIP != nextProps.selectedIP && Utils.isSet(nextProps.selectedIP)) {
            return true;
        }
        if (this.props.config != nextProps.config
            || nextProps.categories != this.props.categories
            || this.props.colorList != nextProps.colorList
            || this.props.slaveSources != nextProps.slaveSources
            || this.props.theme != nextProps.theme
            || this.state != nextState
            || this.props.view != nextProps.view
            || this.props.list != nextProps.list) {
            return true;
        }
        return false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.view.includes('detail') && this.props.graph != nextProps.graph) {
            if (Utils.isSet(nextProps.graph)) {
                const data = nextProps.graph;
                let { epoch } = data;
                epoch = epoch < 60 ? 60 : epoch;
                const newState = update(this.state, {
                    ipDetails: {
                        graphType: { $set: data.graphType },
                        epoch: { $set: epoch },
                        endPoint: { $set: data.endPoint },
                        probe: { $set: data.probe },
                    },
                });
                this.setState(newState);
            } else {
                this.setState(this.getInitialState());
            }
        }
        if (this.props.selectedIP !== nextProps.selectedIP) {
            // when IP changed does not have the probe last graph was viewed at
            const { probe } = this.state.ipDetails;
            const list = nextProps.probesList;
            const slave = probe.split('=')[1];
            if (Utils.isSet(list) && !list.includes(slave)) {
                const newState = update(this.state, {
                    ipDetails: { probe: { $set: `slave=${list[0]}` } },
                });
                this.setState(newState);
            }
        }
    }

    componentWillMount() {
        const ip = this.props.selectedIP;
        if (Utils.isNotSet(ip) && this.props.isDataConfigLoaded) {
            this.props.updateSelectedIp();
        }
    }

    render() {
        const catList = this.getCatList();
        const as = this.getAS();
        return (
            <div id='mother' className={this.props.isMobile ? 'mobile' : ''}>
                { !this.props.isMobile
                    && (
                        <TopPanel
                            {...this.props}
                            ipDetails={this.state.ipDetails}
                            catList={catList}
                            triggerSidebarAction={this.triggerSidebarAction}
                            sidePanelStatus={this.state.sidePanelStatus}
                        />
                    )
                }
                <div className={`main-panel ${this.state.sidePanelStatus ? 'side-relative' : ''}`}>
                    <SidePanel sidePanelStatus={this.state.sidePanelStatus} isNarrow={this.isNarrow} {...this.props} />
                    <div className='content-panel'>
                        {!this.props.isMobile
                        && (<ControlPanel {...this.props} catList={catList} as={as} />)
                        }
                        <GraphContainer
                            ref='graph'
                            generateURL={this.generateURL}
                            graphConfig={this.props.graph}
                            showSetIpModal
                            {...this.props}
                            as={as}
                            catList={catList}
                            isNarrow={this.state.sidePanelStatus}
                            ipDetails={this.state.ipDetails}
                            setActiveProbe={this.setActiveProbe}
                            setGraphType={this.setGraphType}
                            setAccuracyType={this.setAccuracyType}
                            setEpochWithEndpoint={this.setEpochWithEndpoint}
                            setEpochFromZoom={this.setEpochFromZoom}
                            getMobileSearchBox={this.getMobileSearchBox}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default MainPanelDetail;

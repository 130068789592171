/* global $:true */
import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/browser';
import Utils from '../js/utils';
import AddIpModal from './modals/addIP.modal.jsx';
import MainPanelLogin from './mainPanel/mainPanel.login.jsx';
import MainPanelDetail from './mainPanel/mainPanel.detail.jsx';
import MainPanelDashboard from './mainPanel/mainPanel.dashboard.jsx';
import MainPanelDetailProbes from './mainPanel/mainPanel.detailProbes.jsx';
import MainPanelStatus from './settings/status/settings.status.jsx';
import MainPanelSettings from './mainPanel/mainPanel.settings.jsx';
import MainPanelTrace from './mainPanel/mainPanel.trace.jsx';
import LicenseError from './error/licenseError.jsx';
import DefaultError from './error/defaultError.jsx';

class Main extends React.Component {
    constructor(props) {
        super(props);
        // Binding 'this scope' new in ES6
        this.getInitialState = this.getInitialState.bind(this);
        this.loadGeneralSettings = this.loadGeneralSettings.bind(this);
        this.loadSlaveList = this.loadSlaveList.bind(this);
        this.getLicenseDetails = this.getLicenseDetails.bind(this);
        this.loadInitialConfig = this.loadInitialConfig.bind(this);
        this.reloadConfig = this.reloadConfig.bind(this);
        this.getIpsColor = this.getIpsColor.bind(this);
        this.setStateView = this.setStateView.bind(this);
        this.setCategoriesGlobally = this.setCategoriesGlobally.bind(this);
        this.addValToQuickList = this.addValToQuickList.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.setStateConfig = this.setStateConfig.bind(this);
        this.handleDetailSwitch = this.handleDetailSwitch.bind(this);
        this.getSelectedView = this.getSelectedView.bind(this);
        this.logUser = this.logUser.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getAlias = this.getAlias.bind(this);
        this.updateSelectedIp = this.updateSelectedIp.bind(this);
        this.initMainState = this.initMainState.bind(this);
        this.componentDidCatch = this.componentDidCatch.bind(this);
        // Set state migrating from es5 by using previous getInitialState
        this.state = this.getInitialState();
        this.getLostBuffer = {};
        this.getLostBufferCount = 0;
    }

    getLightTheme() {
        return {
            label: 'light',
            colorBckgr: '#FFFFFF',
            colorText: '#C9CBD6',
            shadowReverse: true,
            sideText: '#5A5D70',
            simpleLine: 'rgba(0,0,0,0.5)',
        };
    }

    getDarkTheme() {
        return {
            label: 'dark',
            colorBckgr: '#3F4155',
            colorText: '#474C60',
            shadowReverse: false,
            sideText: '#9496A6',
            simpleLine: 'rgba(200,200,200,0.6)',
        };
    }

    getInitialState() {
        return {
            isLogged: this.props.isLogged,
            user: this.props.user,
            username: this.props.username,
            theme: this.getDarkTheme(),
            selectedView: this.props.view,
            dataConfig: {},
            categories: {},
            probesList: [],
            probesListReport: [],
            generalSettings: {},
            slaveList: {},
            slaveSources: {},
            colorList: [],
            selectedIP: '',
            ipName: '',
            quickList: [],
            license: {},
            hasError: false,
        };
    }

    loadGeneralSettings() { // API CALL GET
        const scope = this;
        const successCallback = function (data) {
            scope.setState({ generalSettings: data });
        };
        const data = Utils.formApiData('/settings', true, 'json', successCallback);
        Utils.performGETcall(data);
        this.loadSlaveList();
    }

    loadSlaveList() { // API CALL GET
        const scope = this;
        const successCallback = function (data) {
            scope.setState({ slaveList: data });
        };
        const data = Utils.formApiData('/slaves', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    getLicenseDetails() {
        const scope = this;
        const successCallback = (data) => {
            scope.getIpsColor(data.license);
            scope.setState({ license: data.license });
        };
        const data = Utils.formApiData('/status/version', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    loadSlaveSource() {
        const successCallback = data => this.setState({ slaveSources: data });
        const data = Utils.formApiData('/status/slaves', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    loadInitialConfig(ip) { // API CALL GET
        const scope = this;
        const successCallback = function (dataAll) {
            const ipsArr = Object.keys(dataAll.ping.ips);
            if (ip == undefined || !ipsArr.includes(ip)) {
                ip = dataAll.ping.lastIP;
                ip = ip == undefined || !ipsArr.includes(ip) ? Object.keys(dataAll.ping.ips)[0] : ip;
            }
            if (ipsArr.length > 0) {
                const ips = Utils.getPropertyOfObject(['ping', 'ips'], dataAll);
                const probesListReport = Utils.getPropertyOfObject([ip, 'report'], ips);
                const slaves = Utils.getPropertyOfObject([ip, 'slaves'], ips);
                const autoGroupKeys = scope.detectAutoGroups(dataAll.groups);
                scope.setState({
                    dataConfig: dataAll,
                    selectedIP: ip,
                    ipName: scope.getTitle(dataAll, ip),
                    probesList: slaves,
                    probesListReport: Utils.isSet(probesListReport) ? probesListReport : [],
                    autoGroupKeys,
                });
            } else {
                scope.setState({ dataConfig: dataAll });
            }
        };
        const data = Utils.formApiData('/config', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    reloadConfig(addNew) { // API CALL GET
        const scope = this;
        const successCallback = (dataAll) => {
            const { lastIP, ips } = dataAll.ping;
            const keys = Object.keys(ips);
            if (keys.length <= 0) {
                scope.setState({ dataConfig: dataAll });
                return;
            }
            let selectedIP = addNew ? lastIP : scope.state.selectedIP;
            if (!keys.includes(selectedIP)) {
                selectedIP = keys[0];
            }
            const title = scope.getTitle(dataAll, selectedIP);
            const { slaves, report } = ips[selectedIP];
            const autoGroupKeys = scope.detectAutoGroups(dataAll.groups);
            scope.setState({
                dataConfig: dataAll,
                selectedIP,
                ipName: title,
                probesList: slaves,
                probesListReport: Utils.isSet(report) ? report : [],
                autoGroupKeys,
            });
        };
        const data = Utils.formApiData('/config', true, 'json', successCallback);
        Utils.performGETcall(data);
    }

    detectAutoGroups = (groups) => {
        if (Utils.isSet(groups)) {
            const keys = Object.keys(groups).filter(key => groups[key].isAutoGroup);
            return keys.map(key => key.replace(/->/g, ''));
        }
        return [];
    }

    getIpsColor(license) { // API CALL GET
        const minimalInstanceSize = 20000; // so far only trip
        const now = Math.ceil(Date.now() / 1000);
        const from = now - 86400;
        const size = Utils.getPropertyOfObject(['pingIP', 'used'], license, 0);
        // if(size > minimalInstanceSize){
        if (false) {
            fetch('/v1/status/groups').then(result => result.json()).then((data) => {
                delete data['__REPORT->']; delete data[''];
                const keys = Object.keys(data);
                this.getLostBufferCount = keys.length;
                keys.forEach((group) => {
                    const url = `/v1/getglost/${from}/${now}/${group}`;
                    fetch(url).then(result => result.json()).then(data => this.addLostGroup(data));
                });
            });
        } else {
            const url = `/getlost/${from}/${now}`;
            const successCallback = data => this.setColorList(data.ping);
            const data = Utils.formApiData(url, true, 'json', successCallback);
            Utils.performGETcall(data);
        }
    }

    addLostGroup = (data) => {
        Object.assign(this.getLostBuffer, { ...data.ping });
        this.getLostBufferCount -= 1;
        if (this.getLostBufferCount === 0) {
            this.setColorList(this.getLostBuffer);
        }
    }

    setStateView(data) {
        this.setState({ selectedView: data });
    }

    setCategoriesGlobally(data) {
        const result = Utils.areObjectsSame(this.state.categories, data);
        if (!result) {
            this.setState({ categories: data });
        }
    }

    setColorList(data) {
        this.setState({ colorList: data });
    }

    addValToQuickList(addData, skipMerging = false) {
        const outData = [];
        let isUnique = true;
        if (!skipMerging) {
            const data = this.state.quickList;
            for (const index in data) {
                const temp = data[index];
                const equality = addData.isIp ? temp.ip == addData.ip : temp.name == addData.name;
                if (!equality) {
                    outData.push(temp);
                } else {
                    isUnique = false;
                }
            }
        }
        if (isUnique) {
            outData.push(addData);
        }
        this.setState({
            quickList: outData,
        });
    }

    deleteQuickList = () => this.setState({ quickList: [] });

    changeColor(theme = this.state.theme.label) {
        theme = theme === 'light' ? this.getLightTheme() : this.getDarkTheme();
        this.setState({ theme });
    }

    setStateConfig(data, setNewIp) {
        if (setNewIp) {
            const ip = Object.keys(data.ping.ips)[0];
            const title = this.getTitle(data, ip);
            this.setState({
                dataConfig: data,
                selectedIP: ip,
                ipName: title,

            });
        } else {
            this.setState({
                dataConfig: data,
            });
        }
    }

    handleDetailSwitch(type) {
        let view = 'detail';
        if (this.state.selectedView == 'detail') {
            view = `detail-${type}`;
        }
        this.setStateView(view);
    }

    getSelectedView() {
        const view = this.state.selectedView;
        const isRoot = this.state.user === 'root';
        switch (view) {
        case 'detail':
            return (
                <MainPanelDetail
                    {...this.props}
                    selectedIP={this.state.selectedIP}
                    ipName={this.state.ipName}
                    updateSelectedIp={this.updateSelectedIp}
                    isDataConfigLoaded={Utils.isSet(this.state.dataConfig)}
                    categories={this.state.categories}
                    config={this.state.dataConfig}
                    setStateConfig={this.setStateConfig}
                    setStateView={this.setStateView}
                    generalSettings={this.state.generalSettings}
                    handleDetailSwitch={this.handleDetailSwitch}
                    reloadConfig={this.reloadConfig}
                    probesList={this.state.probesList} // list of selected probes
                    probesListReport={this.state.probesListReport} // list of selected probes for reporting
                    slaveList={this.state.slaveList} // list of all existing probes
                    view={this.state.selectedView}
                    colorList={this.state.colorList}
                    theme={this.state.theme}
                    alias={this.getAlias()}
                    changeColor={this.changeColor}
                    isRoot={isRoot}
                    autoGroupKeys={this.state.autoGroupKeys}
                    slaveSources={this.state.slaveSources}

                    // sidepanel
                    setCategoriesGlobally={this.setCategoriesGlobally}
                    user={this.state.user}
                    getTitle={this.getTitle}
                    setColorList={this.setColorList}
                    defaultColor={this.state.theme.sideText}
                    IPAddAllowed={this.props.IPAddAllowed}
                    list={this.state.quickList}
                    addValToQuickList={this.addValToQuickList}
                    deleteQuickList={this.deleteQuickList}
                    license={this.state.license}
                />
            );
        case 'dashboard':
            return (
                <MainPanelDashboard
                    config={this.state.dataConfig}
                    getTitle={this.getTitle}
                    categories={this.state.categories}
                    slaveList={this.state.slaveList}
                />
            );
        case 'detail-probes':
            return (
                <MainPanelDetailProbes
                    selectedIP={this.state.selectedIP}
                    ipName={this.state.ipName}
                    view={this.state.selectedView}
                    probesList={this.state.probesList}
                    type='probe'
                    handleDetailSwitch={this.handleDetailSwitch}
                    theme={this.state.theme}
                    config={this.state.dataConfig}
                    generalSettings={this.state.generalSettings}
                    setStateView={this.setStateView}
                />
            );

        case 'detail-ip':
            return (
                <MainPanelDetailProbes
                    list={this.state.quickList}
                    config={this.state.dataConfig}
                    ipName={this.state.ipName}
                    view={this.state.selectedView}
                    slaveList={this.state.slaveList}
                    type='ip'
                    handleDetailSwitch={this.handleDetailSwitch}
                    theme={this.state.theme}
                    generalSettings={this.state.generalSettings}
                    setStateView={this.setStateView}
                />
            );
        case 'trace':
            return (
                <MainPanelTrace
                    selectedIP={this.state.selectedIP}
                    ipName={this.state.ipName}
                    setView={this.setStateView}
                    activeSlave=''
                />
            );
        case 'settings':
            return (
                <MainPanelSettings
                    {...this.props}
                    categories={this.state.categories}
                    selectedIP={this.state.selectedIP}
                    theme={this.state.theme}
                    view={this.state.selectedView}
                    setStateView={this.setStateView}
                    changeColor={this.changeColor}
                    config={this.state.dataConfig}
                    user={this.state.user}
                    username={this.state.username}
                    setStateConfig={this.setStateConfig}
                    updateSelectedIp={this.updateSelectedIp}
                    reloadConfig={this.reloadConfig}
                    slaveList={this.state.slaveList}
                    loadSlaveList={this.loadSlaveList}
                    license={this.state.license}
                    isRoot={isRoot}
                />
            );
        }
    }

    logUser(val, isAdmin, username = this.state.username) {
        // do not refresh state if the value is the same, bcs every ajax call control/call this function
        if (this.state.isLogged != val) {
            this.setState({
                isLogged: val,
                user: isAdmin ? 'root' : 'user',
                username,
            });
        }
    }

    getTitle(data, ip) {
        let title = data.ping.ips[ip];
        if (title != undefined && typeof title !== 'string') {
            title = title.desc;
        }
        if (typeof title === 'string' && title.length > 0) {
            if (!title.includes(ip)) {
                title = `${ip} - ${title}`;
            }
            return title;
        }
        return 'no-title';
    }

    getAlias(data = this.state.dataConfig, ip = this.state.selectedIP) {
        let alias = data.ping.ips[ip];
        if (alias != undefined && typeof alias !== 'string') {
            alias = alias.desc;
        }
        if (typeof alias !== 'string' || alias.length <= 0) {
            alias = 'no-title';
        }
        return alias;
    }

    updateSelectedIp(ip, slaves, title, reportSlaves) {
        const ips = Utils.getPropertyOfObject(['dataConfig', 'ping', 'ips'], this.state, {});
        if (Utils.isNotSet(ip)) {
            const keys = Object.keys(ips);
            ip = Utils.getPropertyOfObject([0], keys);
        }
        if (Utils.isNotSet(title)) {
            title = this.getTitle(this.state.dataConfig, ip);
        }
        if (slaves == undefined) {
            slaves = Utils.getPropertyOfObject([ip, 'slaves'], ips, []);
        }
        if (reportSlaves == undefined) {
            reportSlaves = Utils.getPropertyOfObject([ip, 'report'], ips, []);
        }

        this.setState({
            selectedIP: ip,
            ipName: title,
            probesList: slaves,
            probesListReport: reportSlaves,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.selectedView != 'detail') {
            document.title = `cocopacket - ${nextState.selectedView}`;
        } else if (this.state.selectedIP != nextState.selectedIP) {
            document.title = `cocopacket - ${nextState.selectedIP}`;
        }
        if (this.state != nextState) {
            if (!this.state.isLogged && nextState.isLogged) {
                this.initMainState();
            }
            return true;
        }
        if (nextProps.graph != this.props.graph) {
            return true;
        }
        return false;
    }

    componentWillMount() {
        this.initSentry();
        if (this.state.isLogged) {
            this.initMainState();
        }
    }

    initSentry() {
        Sentry.init({ dsn: 'https://cd6f888a213f4e48a82ab82dc8e2bb5d@sentry.io/1402117' });
    }

    initMainState() {
        this.loadGeneralSettings();
        this.getLicenseDetails();
        this.loadSlaveSource();
        this.loadInitialConfig(this.props.ip, this.props.setStateAfterLoad);
        Utils.setLogUserRef(this.logUser);
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        const view = this.state.selectedView;
        const data = this.state.dataConfig;
        const isRoot = this.state.user === 'root';
        if (this.state.hasError) {
            return (<DefaultError isMobile={this.props.isMobile} />);
        }
        if (!this.state.isLogged) {
            return (
                <div id='mother'>
                    <MainPanelLogin isLogged={this.state.isLogged} logUser={this.logUser} sso={this.props.sso} />
                </div>
            );
        }
        if (Utils.isSet(this.state.license) && (!this.state.license.isValid || this.state.license.isExceeded)) {
            return (<LicenseError isMobile={this.props.isMobile} license={this.state.license} />);
        }
        if (Utils.isNotSet(data) || Utils.isNotSet(this.state.license)) {
            return (
                <div id='mother' />
            );
        }
        if (Object.keys(data.ping.ips).length == 0) {
            return (
                <div id='mother' className='empty-config'>
                    <h1>Ops, seems like your config is empty.</h1>
                    <ul className='only-option'>
                        {Object.keys(this.state.slaveList).length > 0 && isRoot
                        && (
                            <AddIpModal
                                reloadConfig={this.reloadConfig}
                                slaveList={this.state.slaveList}
                                categories={this.state.categories}
                            />
                        )
                        }
                    </ul>
                    {!this.props.isMobile && isRoot
                        && (
                            <MainPanelStatus
                                slaveList={this.state.slaveList}
                                loadSlaveList={this.loadSlaveList}
                                setHash={false}
                                reloadConfig={this.reloadConfig}
                            />
                        )}

                </div>
            );
        }
        return (
            <div id='father' className={`theme-${view === 'detail' ? this.state.theme.label : 'dark'}`}>
                {this.getSelectedView()}
            </div>
        );
    }
}

export default DragDropContext(HTML5Backend)(Main);

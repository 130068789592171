import React from 'react';
import { withTooltip } from 'react-tippy';
import Utils from '../../js/utils.js';
import ProbeSelection from '../common/ProbeSelection.jsx';
import CategorySelection from '../common/CategorySelection.jsx';
import ModalWrapper from './modalWrapper.jsx';

/** *
 * PROPS
 * alias - [string] - alias name
 * reloadConfig - [func] - fetch and reload config
 * slaveList - [obj] - {'AMS':'1.1.1.1', 'LON': '2.2.2.2', ...}
 * probesList - [array] - ['AMS', 'NYC', ...] selected/assigned monitoring locations
 * probesListReport - [array] - ['AMS','LON'...]
 * selectedIP - [string] - '127.0.0.1'
 * catList - [array] - ['CAT_1', 'SUB_CAT_1',...]
 * config - [obj] - config (complex-structure) see at /v1/config
 * setStateConfig - [func] - sets config internally
 * categories - [obj] - {'CAT1':{'CAT1_SUB':{I_P_S[...]},I_P_S:[{ip: "1.1.1.1", title: "one", isfav: false},..]}}
 *
 */

class SettingsIPModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        inputAlias: Utils.isSet(this.props.alias) ? this.props.alias : '',
        selectedProbes: Utils.isSet(this.props.probesList) ? [...this.props.probesList] : [],
        selectedProbesReport: Utils.isSet(this.props.probesListReport) ? [...this.props.probesListReport] : [],
        category: [],
        isFavChecked: false,
        tabSelection: 'probes',
        deleteOption: false,
        newCategories: {},
    })

    setCategory = data => this.setState({ category: data });

    changeFavourites = () => {
        const { isFavChecked } = this.state;
        this.setState({ isFavChecked: !isFavChecked });
    }

    successRedirect = () => {
        this.props.reloadConfig();
        this.setState(this.getInitialState());
    }

    /** *
     *  @param data - [obj] - {'probe1':false, 'probe2':true, 'probe3'; false...}
     *  @param keyState - key in local state
     *  output -> ['probe2'...]
     */
    setSelectedProbes = (data, keyState = 'selectedProbes') => {
        if (keyState === 'selectedProbes') {
            this.setState(prevState => ({ selectedProbesReport: prevState.selectedProbesReport.filter(probe => data[probe]) }));
        }
        this.setState({ [keyState]: Object.keys(data).filter(probe => data[probe]) });
    }

    /** *
     * @param data - [obj] - {{'probe1':'1.1.1.1', 'probe2':'2.2.2.2', 'probe3'; '3.3.3.3'}}
     * @param selectedProbes - [obj] - ['AMS', 'LON', ...]
     * output -> {{'probe1':'false', 'probe2':'true', 'probe3'; 'false'}} - bool is decided on IP's probes in config
     */
    setProbesSelection = (selectedProbes = this.state.selectedProbes) => {
        const data = this.props.slaveList;
        const outputFormat = {};
        const probeKeys = Object.keys(data);
        probeKeys.forEach((key) => {
            outputFormat[key] = selectedProbes.includes(key);
        });
        return outputFormat;
    }

    openModal = () => {
        // initialiaze content
        const ip = this.props.selectedIP;
        let cat = this.props.catList;
        cat = cat == null ? 'not-assigned' : cat;
        let isfav = this.props.config.ping.ips[ip].fav;
        isfav = Utils.isSet(isfav) ? isfav : false;
        this.setState({
            inputAlias: Utils.isSet(this.props.alias) ? this.props.alias : '',
            selectedProbes: Utils.isSet(this.props.probesList) ? [...this.props.probesList] : [],
            selectedProbesReport: Utils.isSet(this.props.probesListReport) ? [...this.props.probesListReport] : [],
            category: cat,
            isFavChecked: isfav,
        });
    }

    deleteAction = (callback) => {
        if (this.state.deleteOption) {
            return (
                <span id='delete-ip-confirm'>
                    <span className='btn btn-delete' onClick={() => this.deleteIP(callback)}>Yes</span>
                    <span className='btn no' onClick={() => this.setState({ deleteOption: false })}>
                        No
                    </span>
                </span>
            );
        }
    }

    deleteIP = (callback) => {
        const ip = this.props.selectedIP;
        const data = this.props.config;
        delete data.ping.ips[ip];
        Utils.deleteIp(ip, callback);
        this.props.setStateConfig(data, true);
    }

    saveChanges = (e, confirmCallback) => {
        e.preventDefault();
        const data = {};
        const ip = this.props.selectedIP;
        data.desc = this.state.inputAlias;
        data.fav = this.state.isFavChecked;
        data.slaves = this.state.selectedProbes;
        let cat = Utils.isSet(this.state.category) ? this.state.category : [];
        cat = cat.length > 0 ? [cat.join('->') + '->'] : [''];
        // hotfix for global report
        const localCats = Utils.getPropertyOfObject(['ping', 'ips', ip, 'cat'], this.props.config, []);
        if (localCats.includes('_GLOBAL-ALERT->')) {
           cat.push('_GLOBAL-ALERT->');
        }
        if (this.state.selectedProbesReport.length > 0) {
            data.report = this.state.selectedProbesReport;
            cat.push('__Report->');
        }
        data.cat = cat;
        Utils.setConfigIp(data, ip, confirmCallback);
    }

    getProbeSelection = () => {
        const { tabSelection } = this.state;
        const reportProps = {};
        if (tabSelection === 'report') {
            reportProps.title = 'Locations for Reporting';
            reportProps.setSelectedProbes = data => this.setSelectedProbes(data, 'selectedProbesReport');
            reportProps.probesList = this.setProbesSelection(this.state.selectedProbesReport);
            reportProps.allowed = this.state.selectedProbes;
            reportProps.tab = 'report';
        }
        const InfoIcon = () => (<i className='fa fa-info-circle' />);
        const tooltipOptions = {
            style: { marginLeft: '20px' },
            theme: 'dark',
            position: 'right',
            animation: 'perspective',
        };
        tooltipOptions.title = `Reporting behavior is customized to client's needs. 
                                Learn more at https://cocopacket.com/reporting.html`;
        const ReportTooltip = withTooltip(InfoIcon, tooltipOptions);
        const ipv4Regex = Utils.getIpRegex(true);
        const isIPv4 = ipv4Regex.test(this.props.selectedIP);
        return (
            <div className='slave-selection-with-tabs'>
                <ul>
                    <li
                        className={tabSelection === 'probes' ? 'active' : ''}
                        onClick={() => {
                            this.setState({ tabSelection: 'probes' });
                        }}
                    >
                        probes
                    </li>
                    <li
                        className={tabSelection === 'report' ? 'active' : ''}
                        onClick={() => {
                            this.setState({ tabSelection: 'report' });
                        }}
                    >
                        report
                        <ReportTooltip />
                    </li>
                </ul>
                <ProbeSelection
                    tab='monitoring'
                    inputIP={this.props.selectedIP}
                    setSelectedProbes={this.setSelectedProbes}
                    probesList={this.setProbesSelection()}
                    ipVersion4={isIPv4}
                    slaveSources={this.props.slaveSources}
                    {...reportProps}
                />
            </div>
        );
    }

    render() {
        const Trigger = ({ action }) => (
            <a className='cntrl-btn ip-settings-trigger' onClick={action}>
                <i className='fa fa-cog' />
            </a>
        );
        const styles = { content: { width: '80%', height: 'auto' } };
        const renderComponent = confirmCallback => (
            <form id='set-ip-form' onSubmit={e => this.saveChanges(e, confirmCallback)}>
                <div className='top-line'>
                    <h2>
                        Set properties
                        {' '}
                        <strong>{this.props.selectedIP}</strong>
                    </h2>
                    <a className='favourite-selection inline' onClick={this.changeFavourites}>
                        <i className={'fa fa-star' + (this.state.isFavChecked ? '' : '-o')} />
                    </a>
                </div>
                <div className='alias-change'>
                    <label className='label-title'>
                        <span>IP ALIAS</span>
                    </label>
                    <input
                        type='text'
                        value={this.state.inputAlias}
                        onChange={e => this.setState({ inputAlias: e.target.value })}
                    />
                </div>
                <CategorySelection
                    catList={this.props.catList}
                    category={this.state.category}
                    setCategory={this.setCategory}
                    selectedIP={this.props.selectedIP}
                    categories={this.props.categories}
                />
                {this.getProbeSelection()}
                <div className='butts multi'>
                    <div className='delete-ip'>
                        <span
                            className='trash-ip'
                            onClick={() => this.setState({ deleteOption: true })}
                        >
                            <i className='fa fa-trash-o' />
                            <span>Delete this IP address</span>
                        </span>
                        {this.deleteAction(confirmCallback)}
                    </div>
                    <button
                        type='submit'
                        className='btn modal-confirm'
                        disabled={this.state.selectedProbes.length == 0}
                    >
                        Save
                    </button>
                </div>
            </form>
        );
        return (
            <ModalWrapper
                Trigger={Trigger}
                style={styles}
                modalID='modal-set-ip'
                redirect={this.successRedirect}
                openModal={this.openModal}
                refs={{}}
                render={renderComponent}
            />
        );
    }
}

export default SettingsIPModal;
